<template functional>
  <td scope="col" 
      :title="`${props.student.name}: ${props.week.getUTCMonth() + 1}/${props.week.getUTCDate()}`" 
      v-bind:class= "{recitalCell: props.is_recital}" 
      class="chartItem chartCell py-0 px-0">
    <div @click="listeners['on-click']({student_id: props.student.id, week: props.week,exists: props.data.lesson != null})" class="star-container align-middle" >
      <font-awesome-icon v-if="props.data.lesson != null && props.data.lesson.points === 4" icon="star" class="star-silver" :size="star_size" fixed-width/>
      <font-awesome-icon v-else-if="props.data.lesson != null && props.data.lesson.points === 5" icon="star" class="star-gold" :size="star_size" fixed-width/>
      <font-awesome-icon v-else-if="props.data.lesson != null && props.data.lesson.points === 3" icon="star" class="star-red" :size="star_size" fixed-width/>
      <font-awesome-icon v-else-if="props.data.lesson != null && props.data.lesson.points === 0" icon="times" class="star-times" :size="star_size" fixed-width/>
      <font-awesome-icon v-else icon="star" class="star-invisible" :size="star_size" fixed-width/>
    </div>
  </td>
</template>

<script>

export default {
  props: ['name', 'student', 'week', 'is_recital', 'data', 'star_size'],

};
</script>

<style scoped>
 .recitalCell{
     border-right: 2px solid darkgray;
 }

</style>