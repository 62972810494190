<template>
  <div class="container-fluid md-2">
    <b-tabs pills v-model="tabIndex">
      <b-tab title="Attendance" active>
        <b-card class="text-center nopad">
          <div class="row">
            <div class="text-left col-sm-4" style="position: relative;">
              
              <vue-moments-ago prefix="Updated " 
                              suffix="ago" 
                              :date=lastUpdate
                              class="mx-3 pu-2"
                              style="position: absolute; bottom: 0;">
              </vue-moments-ago>
            </div>
            <div class="col-sm-4">
              <h3 class="my-2">
                Lesson Attendance for {{ year }}
              </h3>
            </div>
            <div class="text-right col-sm-4" style="position: relative;">
              
              <font-awesome-icon v-if="this.$refs.chart != null && this.$refs.chart.silent_loaded" icon="circle-notch" size="1x" spin style="position: absolute; bottom: 5;right: 5%;"/>
            </div>

          </div>

          <ChartTable
            ref="chart"
            star_size="fa-sm"
            :cell="cell"
            :year="year"
            lessons=True>
          </ChartTable>

        </b-card>
      </b-tab>
      <b-tab title="Minutes">
        <h4 class="text-center py-3">
            Practicing Minutes for {{ lastUpdate | moment("MMMM YYYY") }}
        </h4>

        <MinutesTable
            ref="minutes">
          </MinutesTable>
      </b-tab>
    </b-tabs>
      
  </div>
</template>

<script>
import VueMomentsAgo from 'vue-moments-ago'
import ChartTable from "../components/ChartTable";
import StarChartCell from "../components/StarChartCell"
import MinutesTable from "../components/MinutesTable"

export default {
  name: "Students",
  components: {
    ChartTable,
    MinutesTable,
    VueMomentsAgo
  },

  data: function(){
    return {
      cell: StarChartCell,
      year: new Date().getFullYear(),
      lastUpdate: null,
      tabIndex: 0,
    }
  },

  created () {
      this.lastUpdate = new Date().toISOString();

      const dataRefresh = Number(this.$route.query.dataRefresh) || 60 * 60; // Refresh every hour
      const kioskRotate = Number(this.$route.query.rotate) || 30;           // Update kiosk every 30 seconds

      this.timer = setInterval(this.reload, dataRefresh * 1000) 

      if (kioskRotate > 0){
        this.kiosk_timer = setInterval(this.nextTab, kioskRotate * 1000) 
      }
      
  },

  methods:{
    async reload() {
      this.lastUpdate = new Date().toISOString();
      //console.log(this.lastUpdate)
      await this.$refs.chart.getLessons(true);
      this.$refs.chart.is_loaded = true

      this.$refs.minutes.update()
    },

    async nextTab(){
      if (this.tabIndex >= 1){
        this.tabIndex = 0;
      }else{
        this.tabIndex += 1;
      }
    },

  },

}
</script>

<style>
.nopad > div.card-body{
  padding: 0px;
}

.nopad{
  border: none;

}

ul[role="tablist"]{
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}


</style>